// extracted by mini-css-extract-plugin
export var column = "ProviderSelectionTile__column__yLkLJ";
export var container = "ProviderSelectionTile__container__SniXK";
export var flex = "ProviderSelectionTile__flex__B5W6I";
export var flexColumn = "ProviderSelectionTile__flexColumn__BRshd";
export var gap1 = "ProviderSelectionTile__gap1__qEBUW";
export var gap2 = "ProviderSelectionTile__gap2__jr3qV";
export var gap3 = "ProviderSelectionTile__gap3__bOAFI";
export var gap4 = "ProviderSelectionTile__gap4__GGJmF";
export var gap5 = "ProviderSelectionTile__gap5__R50BJ";
export var logo = "ProviderSelectionTile__logo__O833y";
export var providerName = "ProviderSelectionTile__providerName__gcNd1";
export var row = "ProviderSelectionTile__row__OTzKI";